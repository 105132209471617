import React, { useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Header from "../components/Header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import SideBarDrawer from "../components/SideBarDrawer/SideBarDrawer";
import "../assets/css/global.css";
import "../assets/css/form.css";
import { Navigate, useLocation } from "react-router-dom";
import { getUserAuthData, getUserData } from "../hooks/HelperFunctions";

const MainLayout = ({ children, collapsed }) => {
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const location = useLocation();
  const userAuthenticated = getUserAuthData();
  // retrive user data
  const userData = getUserData();
  // assign user role
  const userRole = userData.userRole === "undefined" ? "" : userData.userRole;
  if (userAuthenticated && userRole === "owner") {
    return (
      <>
        {/* <Header />
      {children} */}
        <div className="DesktopView">
          <Header />
          <Sidebar collapsed={collapsed} SchoolRoute={true} />
        </div>

        <div className="ResponsiveMenu">
          <Header
            IconMenu={
              <a onClick={handleDrawerOpen}>
                <MenuRoundedIcon />
              </a>
            }
          />

          <SideBarDrawer
            open={open}
            handleDrawerClose={handleDrawerClose}
            DrawerBody={<Sidebar />}
          />
        </div>
        <div className="app-body"> {children} </div>
      </>
    );
  }

  return <Navigate to="/signin" state={{ from: location }} replace></Navigate>;
};

export default MainLayout;
