import React, { useEffect, useState } from "react";

import InnerPageLayout from "../../components/InnerPageLayout/InnerPageLayout";

import { RiFileInfoFill } from "react-icons/ri";
import { RiGroupLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import MenuBookIcon from "@mui/icons-material/MenuBook";

import TeachingProfile from "./TeachingProfile";
import axios from "axios";
import { useParams } from "react-router-dom";

const AccountsDetails = () => {
  const { id } = useParams();
  const [organizationDetails, setOrganizationDetails] = useState([]);
  const baseUrl =
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const getOrganizationDetails = () => {
    let data = {
      orgId: id,
    };
    axios
      .post(`${baseUrl}/ownerPortal/getOrganizationDetails`, data)
      .then((response) => {
        if (!response.data.error) {
          setOrganizationDetails(response.data?.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getOrganizationDetails();
  }, []);
  const breadcrumbsData = [
    {
      label: "Accounts",
      link: "/accounts",
      icon: <MenuBookIcon />,
      //handleClick={handleClick}
    },

    {
      label: organizationDetails?.teachingAccName,
    },
  ];

  const sideBarTabLabel = [
    {
      icon: <RiGroupLine />,
      label: "Teaching Profile",
    },
    {
      icon: <RiGroupLine />,
      label: "Courses",
    },
    {
      icon: <GrGroup />,
      label: "Users",
    },
    {
      icon: <CampaignOutlinedIcon />,
      label: "Payments",
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Plan",
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Billing History",
    },
    {
      icon: <CalendarTodayOutlinedIcon />,
      label: "Referrals",
    },
  ];

  const sideBarTabBody = [
    {
      id: 0,
      panel: (
        <TeachingProfile
          organizationDetails={organizationDetails}
          getOrganizationDetails={getOrganizationDetails}
        />
      ),
    },
    {
      id: 1,
      panel: "dsfsdfdsfsdv",
    },
    {
      id: 2,
      panel: "dsfsddsvsdvsdvsdvf",
    },
  ];

  return (
    <div>
      <InnerPageLayout
        BackArrow={true}
        BackArrowlink="/accounts"
        // BackArrowOnlcikc={BackArrowOnlcikc}
        CloseArrow={true}
        BreadcrumbsStatus={true}
        breadcrumbsData={breadcrumbsData}
        SideBarStatus={true}
        sideBarTabLabel={sideBarTabLabel}
        sideBarTabBody={sideBarTabBody}
      />
    </div>
  );
};

export default AccountsDetails;
