import React, { useState, useEffect } from "react";
import "../../assets/css/SideBarMenu.css";
import { Sidebar, Menu } from "react-pro-sidebar";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useLocation } from "react-router-dom";
import { RiSurveyLine } from "react-icons/ri";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import CustomMenuItem from "./CustomMenuItem";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";

function SideBarMenu(props) {
  const pathname = useLocation().pathname;
  const takbirLogo =
    "https://cdn.mytakbir.com/static-assets/logos/takbir-lms-learning-management-system-muslim-logo-3x.png";
  const takbirUrl = "https://mytakbir.com/";

  return (
    <div className="SideBarContainer">
      <div className="SideBarInnerContainer">
        <div className="BrandMainLogo">
          {takbirUrl?.length > 0 && (
            <a
              className="Branding"
              href={`${takbirUrl === "" ? takbirUrl : "#"}`}
            >
              <img
                src={takbirLogo}
                style={{ height: "40px", objectFit: "contain" }}
                alt="logo"
              />
            </a>
          )}
        </div>

        <div className="SideBarMenu">
          <Sidebar collapsed={props.collapsed}>
            <Menu>
              <CustomMenuItem
                className={`${pathname === "/" ? "LinkActive" : "link"}`}
                href="/"
              >
                <HomeRoundedIcon /> <span className="MenuPer">Home</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/id">
                    Home
                  </a>
                </div>
              </CustomMenuItem>

              <CustomMenuItem
                className={`${pathname === "/inbox" ? "LinkActive" : "link"}`}
                href="/inbox"
              >
                <QuestionAnswerRoundedIcon />{" "}
                <span className="MenuPer">Inbox</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/inbox">
                    Inbox
                  </a>
                </div>
              </CustomMenuItem>

              <div className="divider"></div>

              <CustomMenuItem
                className={`${
                  pathname === "/accounts" ? "LinkActive" : "link"
                }`}
                href="/accounts"
              >
                <MenuBookRoundedIcon />{" "}
                <span className="MenuPer">Accounts</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/accounts">
                    Accounts
                  </a>
                </div>
              </CustomMenuItem>

              <CustomMenuItem
                className={`${pathname === "/users" ? "LinkActive" : "link"}`}
                href="/users"
              >
                <PersonRoundedIcon /> <span className="MenuPer">Users</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/users">
                    Users
                  </a>
                </div>
              </CustomMenuItem>

              <CustomMenuItem
                className={`${pathname === "/team" ? "LinkActive" : "link"}`}
                href="/team"
              >
                <GroupsRoundedIcon /> <span className="MenuPer">Team</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/team">
                    Team
                  </a>
                </div>
              </CustomMenuItem>

              <div className="divider"></div>
              <CustomMenuItem
                className={`${
                  pathname === "/settings" ? "LinkActive" : "link"
                }`}
                href="/settings"
              >
                <SettingsRoundedIcon />{" "}
                <span className="MenuPer">Settings</span>{" "}
                <div className="MenuTooltip">
                  <a className="MenuTooltipLink" href="/settings">
                    Settings
                  </a>
                </div>
              </CustomMenuItem>
            </Menu>
          </Sidebar>
        </div>
      </div>
    </div>
  );
}

export default SideBarMenu;
