import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import dateFormat, { masks } from "dateformat";
import TableCell from "@mui/material/TableCell";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import Buttons from "../Form/Button/Button";
import Tooltip from "../Form/Tooltip/Tooltip";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SelectField from "../Form/SelectField/SelectField";
import CheckIcon from "@mui/icons-material/Check";
import "./Table.css";

function TableCells(props) {
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const open = Boolean(anchorElMenu);
  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const [timezone, setTimezone] = React.useState(() => {
    const timezone = localStorage.getItem("timezone");
    return timezone ? timezone : "America/New_York";
  });

  const [checked, setChecked] = React.useState(true);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionBtnHandler = (label, path, id, item) => {
    setAnchorEl(null);
    if (item.onClick) {
      item.onClick();
    } else {
      console.log(label, path);
      if (label === "View") {
        // Redirects to course listing page
        navigate(path, { replace: true });
      } else if (label === "Edit") {
        // Redirects to course listing page
        navigate(path, { replace: true });
      } else if (label === "Delete") {
        props.handleOpenDialog(id);
      } else if (item.extraOperation) {
        props.handleOpenDialog(id, item);
      }
    }
  };

  const btnHandleClick = (id, operation) => {
    console.log(id);
    if (typeof props.btnHandleClick !== "undefined") {
      props.btnHandleClick(id, operation);
    }
  };

  const showIcon = (iconName) => {
    switch (iconName) {
      case "ModeEditOutlineOutlinedIcon":
        return <ModeEditOutlineOutlinedIcon />;
      case "DeleteOutlineOutlinedIcon":
        return <DeleteOutlineOutlinedIcon />;
      case "FileDownloadOutlinedIcon":
        return <FileDownloadOutlinedIcon />;
      case "InsertLinkOutlinedIcon":
        return <InsertLinkOutlinedIcon />;
      default:
        return null;
    }
  };

  const iconButtonHandler = (operationType, id) => {
    props.iconButtonHandler(operationType, id);
  };

  return (
    <>
      {props.cell.map((item, i) => (
        <TableCell
          className="TableCell"
          style={{
            background: item.CellColor,
            maxWidth: item.width,
            paddingRight: item.padding,
            cursor: item.cursor,
          }}
          onClick={item.onClick}
        >
          {item.SelectFiled && (
            <span className="TableSelectFiled">
              <SelectField
                isDisabled={item.isDisabled}
                selectedOption={item.selectedOption}
                options={item.options}
                handleChange={item.SelectHandleChanges}
              />
            </span>
          )}

          <span
            className="CellCont"
            style={{ float: item.align, justifyContent: item.TextAlign }}
          >
            {item.Check && (
              <span className="TableCheckbox">
                <Checkbox
                  defaultChecked={item?.defaultChecked}
                  color={item?.checkBoxColor ? item?.checkBoxColor : "primary"}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onClick={(event) => props.handleClick(event, props.cell)}
                  checked={props.isItemSelected}
                />
              </span>
            )}

            {item.Radio && (
              <span className="TableRedio">
                <Radio
                  checked={item.selectedValue}
                  onChange={item.handleChangeRedio}
                  value={item.RedioValue}
                  name="radio-button-demo"
                  inputProps={{ "aria-label": `A` }}
                />
              </span>
            )}

            <span className="CellImg">
              {item.imgStatus && <Avatar src={item.img} />}
              {item.imgTextLink ? (
                <a href={item.imgTextLink}>{item.imgText}</a>
              ) : (
                <span className="textBold">{item.imgText} </span>
              )}
            </span>

            <span className="CellImg icon">
              {item.cardicon}
              <span className="textBold">{item.number}</span>
            </span>

            {item.iconStatus && (
              <span className="tableIcon">
                <a
                  href={item.iconLink}
                  onClick={
                    item.operationType
                      ? () => iconButtonHandler(item.operationType, item.id)
                      : () => {}
                  }
                >
                  {showIcon(item.icon)}
                </a>
              </span>
            )}

            <div style={{ display: "flex", alignItems: "center" }}>
              {/* {item.Check &&
              <span className="TableCheckbox">
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  onChange={item.onChange}
                />
              </span>
            } */}
              {item.textBold && (
                <span className="textBold" style={{ color: item.textcolor }}>
                  {item.textBold}
                  <Tooltip
                    TooltipText={item.tooltipText}
                    Tooltip={
                      <a className="TextWithLinkText" href={item.link}>
                        {item.TextWithLinkText}
                      </a>
                    }
                  />
                </span>
              )}
              {item.textLightStatus && (
                <a href={item.textLink} className="textLightLink">
                  {item.textLight}
                </a>
              )}
              {item.text && (
                <span
                  className="text"
                  style={{
                    color: item.textcolor,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {item.IconStart && (
                    <span style={{ color: "#676C7B", marginRight: "5px" }}>
                      {item.iconStart}
                    </span>
                  )}
                  {item.text}
                </span>
              )}
              {item.date && <span className="textDate">{item.date}</span>}
              {item.zoneBasedDate && (
                <span className="textDate">
                  {moment(item.zoneBasedDate)
                    .tz(item.timezone ? item.timezone : timezone)
                    .format("ddd, MMM Do, YYYY z")}
                </span>
              )}
              {item.subTitle && (
                <span className="subTitle">{item.subTitle}</span>
              )}
            </div>

            {item.Status && (
              <span
                className="status rounded statussmall"
                style={{
                  marginLeft: item.space,
                  background: item.background,
                  color: item.color,
                  display: item.align,
                  width: item.StatusWidth,
                }}
              >
                {item.statusLine && (
                  <div
                    className="StatusLine"
                    style={{ background: item.linebackground }}
                  ></div>
                )}
                {item.label}
              </span>
            )}

            {item.VerifyIcon && (
              <span
                className="TableStatusIcon"
                style={{ background: item.background }}
              >
                {item.icon}
              </span>
            )}

            <div className="tablelink">
              {item.chipStatus && (
                <span className="Tablechip rounded">{item.chip}</span>
              )}
              {item.linkTextStatus && (
                <a
                  onClick={item.onClickLinkText}
                  href={item.link}
                  className="TableLinkText"
                >
                  {item.linkText}{" "}
                  {item.verifiedIcon && (
                    <span className="TableverifiedIcon">
                      <CheckIcon />
                    </span>
                  )}
                </a>
              )}
            </div>

            {item.largeText && (
              <div className="largeText">
                <Tooltip
                  TooltipText={item?.tooltipLargeText}
                  Tooltip={item.largeText}
                />
              </div>
            )}

            {item.Button && (
              <div className="tableButtons" onClick={item.onClick}>
                <Tooltip
                  TooltipText={item.TooltipText}
                  Tooltip={
                    <Buttons
                      label={item.buttonLabel}
                      icon={showIcon(item.buttonIcon)}
                      buttonColor={item.buttonColor}
                      border={item.buttonBorder}
                      color={item.buttonTextcolor}
                      textTransform={item.textTransform}
                      width="auto"
                      height={props.height}
                      fontSize={
                        item.buttonFontSize ? item.buttonFontSize : "14px"
                      }
                      link={item?.buttonLink}
                      target={item?.buttonTarget}
                      disabled={item?.disabled}
                      onClick={
                        item.onClick
                          ? item.onClick
                          : () => btnHandleClick(item?.id, item?.operation)
                      }
                      padding={item.padding}
                      align={item?.align ? item.align : "rigth"}
                    />
                  }
                />
              </div>
            )}

            {item.Component && <div className="">{item.Component}</div>}

            {item.ActionButton && (
              <div className="ActionButtons">
                <Button onClick={handleClickMenu}>
                  {item.ActionButtonLabel} <KeyboardArrowDownOutlinedIcon />
                </Button>
              </div>
            )}

            {item.ActionButtonDotIcon && (
              <div className="ActionButtonsDotIcon">
                <Button onClick={handleClickMenu}>
                  <MoreVertIcon />
                </Button>
              </div>
            )}
          </span>
        </TableCell>
      ))}

      <div className="ActionListing">
        <div>
          <Menu
            id="fade-menu"
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            TransitionComponent={Fade}
          >
            {props.action?.map((item, i) => (
              <a href={item.link}>
                <MenuItem
                  onClick={() =>
                    actionBtnHandler(item.label, item?.path, item?.id, item)
                  }
                  className="TableActionMenu"
                >
                  {item.label}
                </MenuItem>
              </a>
            ))}
          </Menu>
        </div>
      </div>
    </>
  );
}

export default TableCells;
