import React, { useEffect, useState } from "react";

import TableHeader from "../../components/Table/TableHeader";
import TableSubHeader from "../../components/Table/TableSubHeader";
import Table from "../../components/Table/Table";
import DomainRoundedIcon from "@mui/icons-material/DomainRounded";
import moment from "moment-timezone";
import { capitalizeFirstLetter } from "../../hooks/HelperFunctions";
import axios from "axios";
import { toast } from "react-toastify";

const TeachingAccounts = ({ teachingAccountsList, getTeachingAccounts }) => {
  const [rows, setRows] = useState([]);
  //===== Table Data Started === //
  const baseUrl =
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  const onClick = () => {
    alert("onclick working fine!");
  };

  useEffect(() => {
    if (teachingAccountsList?.length > 0) {
      // console.log(teachingAccountsList?.length, 345);
      setRows(convertCellsToTheRow(teachingAccountsList));
    }
  }, [teachingAccountsList]);

  //================================ stauts change =======================================//
  const handleUpdateOrganizationStatus = (id, status) => {
    let data = {
      orgId: id,
      status: status, //pending, active, blocked
    };
    axios
      .post(`${baseUrl}/ownerPortal/updateOrganizationStatus`, data)
      .then((response) => {
        if (!response.data.error) {
          toast.success("TA status has been changed");
          getTeachingAccounts();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //================================ stauts change =======================================//

  const headCells = [
    { label: "TA Name", width: "25%", textAlign: "left" },
    { label: "Type", width: "18%", textAlign: "left" },
    { label: "Owner", width: "15%", textAlign: "left" },
    { label: "Stream", width: "10%", textAlign: "left" },
    { label: "Created", width: "10%", textAlign: "left" },
    { label: "TA Status", width: "10%", textAlign: "left" },
    { label: "", width: "10%", textAlign: "left" },
  ];
  const convertCellsToTheRow = (array) => {
    if (array?.length > 0) {
      return array?.map((item) => {
        let background = "";
        let color = "";
        let label = "";
        let linebackground = "";
        if (capitalizeFirstLetter(item?.accountStatus) === "Active") {
          background = "#E1F2E8";
          color = "#0B9444";
          linebackground = "#41AB37";
          label = "Active";
        } else if (capitalizeFirstLetter(item?.accountStatus) === "Pending") {
          background = "#FFF5E6";
          color = "#FFAE35";
          linebackground = "#FFAA00";
          label = "Pending";
        } else {
          background = "#FCE7E9";
          color = "#E63946";
          linebackground = "#BA1A1A";
          label = "Blocked";
        }
        return {
          cell: [
            {
              linkText: item?.teachingAccName,
              linkTextStatus: true,
              link: "#",
            },
            {
              text: item?.accountType === "org" ? "Organization" : "Individual",
              iconStart: <DomainRoundedIcon />,
              IconStart: true,
            },
            {
              linkText: `${
                item?.owner?.user?.first_name
                  ? `${item?.owner?.user?.first_name} ${item?.owner?.user?.last_name}`
                  : "N/A"
              }`,
              linkTextStatus: true,
              link: "#",
              verifiedIcon: true,
            },
            {
              text: item?.stream ? capitalizeFirstLetter(item?.stream) : "N/A",
            },
            { text: moment(item?.created_at)?.format("DD-MMM-YYYY") },
            {
              Status: true,
              background: background,
              color: color,
              linebackground: linebackground,
              statusLine: true,
              label: label,
            },
            {
              ActionButtonDotIcon: true,
              ActionButtonLabel: "Action",
              TextAlign: "right",
            },
          ],

          //======= Action Date Start===== //
          action: [
            { label: "View", link: `/accounts-details/${item?.orgId}` },
            capitalizeFirstLetter(item?.accountStatus) === "Pending" && {
              label: "Approve Now",
              onClick: (e) =>
                handleUpdateOrganizationStatus(item?.orgId, "active"),
            },
            capitalizeFirstLetter(item?.accountStatus) === "Active" && {
              label: "Revoke Access",
              onClick: (e) =>
                handleUpdateOrganizationStatus(item?.orgId, "blocked"),
            },
            capitalizeFirstLetter(item?.accountStatus) === "Blocked" && {
              label: "Restore Access",
              onClick: (e) =>
                handleUpdateOrganizationStatus(item?.orgId, "active"),
            },
          ],
          //======= Action Date End===== //
        };
      });
    } else {
      return [];
    }
  };
  // const rows = [
  //   {
  //     cell: [
  //       {
  //         linkText: "Hikmah Academy of California",
  //         linkTextStatus: true,
  //         link: "#",
  //       },
  //       {
  //         text: "Hikmah Academy of California",
  //         iconStart: <DomainRoundedIcon />,
  //         IconStart: true,
  //       },
  //       {
  //         linkText: "Omar Suleiman",
  //         linkTextStatus: true,
  //         link: "#",
  //         verifiedIcon: true,
  //       },
  //       { text: "Online" },
  //       { text: "21-Jan-2024" },
  //       {
  //         Status: true,
  //         background: "#E1F2E8",
  //         color: "#0B9444",
  //         linebackground: "#41AB37",
  //         statusLine: true,
  //         label: "Active",
  //       },
  //       {
  //         ActionButtonDotIcon: true,
  //         ActionButtonLabel: "Action",
  //         TextAlign: "right",
  //       },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", link: "/accounts-details" },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  //   {
  //     cell: [
  //       {
  //         linkText: "Hikmah Academy of California",
  //         linkTextStatus: true,
  //         link: "#",
  //       },
  //       {
  //         text: "Hikmah Academy of California",
  //         iconStart: <DomainRoundedIcon />,
  //         IconStart: true,
  //       },
  //       {
  //         linkText: "Zakir Naik",
  //         linkTextStatus: true,
  //         link: "#",
  //         verifiedIcon: true,
  //       },
  //       { text: "Online" },
  //       { text: "21-Jan-2024" },
  //       {
  //         Status: true,
  //         background: "#FFF5E6",
  //         color: "#FFAE35",
  //         linebackground: "#FFAA00",
  //         statusLine: true,
  //         label: "Pending",
  //       },
  //       {
  //         ActionButtonDotIcon: true,
  //         ActionButtonLabel: "Action",
  //         TextAlign: "right",
  //       },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", link: "/accounts-details" },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  //   {
  //     cell: [
  //       {
  //         linkText: "Hikmah Academy of California",
  //         linkTextStatus: true,
  //         link: "#",
  //       },
  //       {
  //         text: "Hikmah Academy of California",
  //         iconStart: <DomainRoundedIcon />,
  //         IconStart: true,
  //       },
  //       { linkText: "Omar Suleiman", linkTextStatus: true, link: "#" },
  //       { text: "Online" },
  //       { text: "21-Jan-2024" },
  //       {
  //         Status: true,
  //         background: "#FCE7E9",
  //         color: "#E63946",
  //         linebackground: "#BA1A1A",
  //         statusLine: true,
  //         label: "Blocked",
  //       },
  //       {
  //         ActionButtonDotIcon: true,
  //         ActionButtonLabel: "Action",
  //         TextAlign: "right",
  //       },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", link: "/accounts-details" },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },
  // ];

  //===== Table Data End === //

  return (
    <div>
      <Table
        headCells={headCells}
        // rows={teachingAccountsList}
        rows={rows}
        footer={true}
        tableHeader={true}
      />
    </div>
  );
};

export default TeachingAccounts;
