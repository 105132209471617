import React from "react";
import ComingSoon from '../../components/ComingSoon/ComingSoon';







const Home = () => {
  return <div>

    <ComingSoon />
  </div>;
};


export default Home;
