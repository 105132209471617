import React, { useEffect } from "react";
import "./Status.css";
import Button from "@mui/material/Button";
import "remixicon/fonts/remixicon.css";
import StatusDropdown from "./StatusDropdown";
import StatusStatic from "./StatusStatic";
import { useNavigate } from "react-router-dom";

const Status = (props) => {
  const {
    MainTitle,
    statusDropdownData,
    defaultValue,
    staticStatustData,
    StaticStatus,
    BottomLine,
    StatusDropdownStatus,
  } = props;

  useEffect(() => {
    console.log(defaultValue, "show me some meaning", 345);
  }, [defaultValue]);
  const handleChange = (event, value) => {
    console.log(event.target.value, value);
  };

  // const statusDropdownData = [
  //     { value: 3, statuscolor: "#3CC480", select: "Active" },
  //     { value: 2, statuscolor: "#FF9800", select: "Archive" },
  //     { value: 1, statuscolor: "#7266FB", select: "Active" },
  //     { value: 4, statuscolor: "#818E94", select: "On hold" },
  //     { value: 5, statuscolor: "#C44058", select: "Canceled" },
  // ];

  // const staticStatustData = [
  //     {
  //       Label: "Course Code",
  //       SubLabel: "Subject Area",
  //       Color: "#7D8597",
  //       line: false,
  //     }
  //   ];

  return (
    <>
      <div className="StatusContainer">
        {MainTitle && (
          <div className="StatusLeftCol">
            <Button
              className="BackLink"
              href={props.Backlink}
              onClick={props.BackLinkLabelOnClick}
            >
              <i class="ri-corner-left-up-fill"></i> {props.BackLinkLabel}
            </Button>
            <h1>{props.Title}</h1>
          </div>
        )}

        <div className="StatusRightCol">
          {StatusDropdownStatus && (
            <li>
              <div className="StatusChipCard">
                <StatusDropdown
                  label="Assignee:"
                  labelTop={false}
                  labelinsideStatus={true}
                  statustData={statusDropdownData}
                  Value={props.Value}
                  defaultValue={defaultValue}
                  handleChange={(e) => {
                    if (props?.onChange) {
                      props?.onChange(e);
                    }
                  }}
                  height="40px"
                  width="auto"
                  Status={true}
                />
              </div>
            </li>
          )}

          {StaticStatus && (
            <>
              {staticStatustData.map((item, i) => (
                <li>
                  <StatusStatic
                    StatusLabel={item.Label}
                    StatusSubLabel={item.SubLabel}
                    StatusColor={item.Color}
                    StatusLine={item.line}
                  />
                </li>
              ))}
            </>
          )}
        </div>
      </div>

      {BottomLine && (
        <div style={{ background: "#E8EAED", height: " 1px" }}></div>
      )}
    </>
  );
};

export default Status;

// *================================================
