import React from 'react'
import './ChipStatus.css';
import Button from '@mui/material/Button';



{/* <ChipStatus
    StatusLabel="Course Code"
    StatusColor="#7D8597"
    StatusLine={false}
/> */}




const ChipStatus = (props) => {

    return (
        <div className='ChipStatusContainer'>
            <div className='ChipStatusBackground' style={{ background: props.StatusColor }}></div>
            {props.StatusLine &&
                <div className="ChipStatusLine" style={{ background: props.StatusColor }}></div>
            }
            <h2>{props.StatusLabel}</h2>
        </div>
    )
}

export default ChipStatus;



