import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import DomainRoundedIcon from "@mui/icons-material/DomainRounded";
import SideDrawer from "../../components/SideDrawer/SideDrawer";

import ProspectsDetails from "./ProspectsDetails";
import moment from "moment-timezone";
import axios from "axios";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../hooks/HelperFunctions";

const Prospects = ({ getAllTheOrganizationInvitationList, getProspects }) => {
  const baseUrl =
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const [rows, setRows] = useState([]);
  //==== New Teaching Account Drawer Start ===//
  const [openDetails, setOpenDetails] = useState(false);
  const [organizationInvitationDetails, setOrganizationInvitationDetails] =
    useState({});
  const handleDrawerOpenDetails = (id) => {
    getOrganizationInvitationDetails(id);
    setOpenDetails(true);
  };
  const handleDrawerCloseDetails = () => {
    setOpenDetails(false);
  };
  //==== New Teaching Account Drawer End ===//

  //============================== listing data===========================================//
  useEffect(() => {
    if (getAllTheOrganizationInvitationList?.length > 0) {
      setRows(convertCellsToTheRow(getAllTheOrganizationInvitationList));
    }
  }, [getAllTheOrganizationInvitationList]);
  //============================== listing data===========================================//
  //===================== get getOrganizationInvitationDetails ================================//

  const getOrganizationInvitationDetails = (id) => {
    let data = {
      invitation_id: id,
    };
    axios
      .post(`${baseUrl}/ownerPortal/getOrganizationInvitationDetails`, data)
      .then((response) => {
        // console.log(response, 345);
        if (!response.data.error) {
          setOrganizationInvitationDetails(response?.data?.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //===================== get getOrganizationInvitationDetails ================================//
  //================================ delete invitation =======================================//
  const handleDeleteInvitation = (id) => {
    let data = {
      invitation_id: id,
    };
    axios
      .post(`${baseUrl}/ownerPortal/deleteOrganizationInvitation`, data)
      .then((response) => {
        if (!response.data.error) {
          getProspects();
          handleDrawerCloseDetails();
          toast.success("Invitation deleted");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //================================ delete invitation =======================================//

  //================================ resend invitation =======================================//
  const handleResendInvitation = (id) => {
    let data = {
      invitation_id: id,
    };
    axios
      .post(`${baseUrl}/ownerPortal/resendInvitationToOrganization`, data)
      .then((response) => {
        if (!response.data.error) {
          getProspects();
          getOrganizationInvitationDetails(id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //================================ resend invitation =======================================//
  //===== Table Data Started === //
  const headCells = [
    { label: "Name", width: "20%", textAlign: "left" },
    { label: "Type", width: "20%", textAlign: "left" },
    { label: "Email", width: "20%", textAlign: "left" },
    { label: "Stream", width: "10%", textAlign: "left" },
    { label: "Invited", width: "10%", textAlign: "left" },
    { label: "Status", width: "20%", textAlign: "left" },
    { label: "", width: "10%", textAlign: "left" },
  ];

  const convertCellsToTheRow = (array) => {
    if (array?.length > 0) {
      return array?.map((item) => {
        let background = "";
        let color = "";
        let label = "";
        let linebackground = "";
        if (item?.status === "accepted") {
          background = "#E1F2E8";
          color = "#0B9444";
          linebackground = "#41AB37";
          label = "Accepted";
        } else if (item?.status === "pending") {
          background = "#E8EAED";
          color = "#5C677D";
          linebackground = "#5C677D";
          label = "Invitation Pending";
        } else {
          background = "#EEEDFF";
          color = "#7266FB";
          linebackground = "#7266FB";
          label = "New";
        }
        return {
          cell: [
            {
              linkText: `${item?.indFirstName} ${item?.indLastName}`,
              linkTextStatus: true,
              link: "#",
            },
            {
              text: item?.accountType === "org" ? "Organization" : "Individual",
              iconStart: <DomainRoundedIcon />,
              IconStart: true,
            },
            {
              text: item?.indEmailAddress,
              linkTextStatus: true,
              link: "#",
            },
            {
              text: item?.stream ? capitalizeFirstLetter(item?.stream) : "N/A",
            },
            { text: moment(item?.created_at)?.format("DD-MMM-YYYY") },
            {
              Status: true,
              background: background,
              color: color,
              linebackground: linebackground,
              statusLine: true,
              label: label,
            },
            {
              ActionButtonDotIcon: true,
              ActionButtonLabel: "Action",
              TextAlign: "right",
            },
          ],

          //======= Action Date Start===== //

          action: [
            item?.status !== "accepted" && {
              label: "View",
              onClick: (e) => handleDrawerOpenDetails(item?.id),
            },
            item?.status !== "accepted" && {
              label: "Resend ",
              onClick: (e) => handleResendInvitation(item?.id),
            },
            {
              label: "Delete",
              onClick: (e) => handleDeleteInvitation(item?.id),
            },
          ],
          //======= Action Date End===== //
        };
      });
    } else {
      return [];
    }
  };

  // const rows = [
  //   {
  //     cell: [
  //       { linkText: "Omar Suleiman", linkTextStatus: true, link: "#" },
  //       {
  //         text: "Hikmah Academy of California",
  //         iconStart: <DomainRoundedIcon />,
  //         IconStart: true,
  //       },
  //       {
  //         linkText: "realomersuleiman@gmail.com",
  //         linkTextStatus: true,
  //         link: "#",
  //       },
  //       { text: "Manual" },
  //       { text: "21-Jan-2024" },
  //       {
  //         Status: true,
  //         background: "#E1F2E8",
  //         color: "#0B9444",
  //         linebackground: "#41AB37",
  //         statusLine: true,
  //         label: "Accepted",
  //       },
  //       {
  //         ActionButtonDotIcon: true,
  //         ActionButtonLabel: "Action",
  //         TextAlign: "right",
  //       },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", onClick: handleDrawerOpenDetails },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },

  //   {
  //     cell: [
  //       { linkText: "Omar Suleiman", linkTextStatus: true, link: "#" },
  //       {
  //         text: "Hikmah Academy of California",
  //         iconStart: <DomainRoundedIcon />,
  //         IconStart: true,
  //       },
  //       {
  //         linkText: "realomersuleiman@gmail.com",
  //         linkTextStatus: true,
  //         link: "#",
  //       },
  //       { text: "Manual" },
  //       { text: "21-Jan-2024" },
  //       {
  //         Status: true,
  //         background: "#EEEDFF",
  //         color: "#7266FB",
  //         linebackground: "#7266FB",
  //         statusLine: true,
  //         label: "New",
  //       },
  //       {
  //         ActionButtonDotIcon: true,
  //         ActionButtonLabel: "Action",
  //         TextAlign: "right",
  //       },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", onClick: handleDrawerOpenDetails },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },
  //   {
  //     cell: [
  //       { linkText: "Omar Suleiman", linkTextStatus: true, link: "#" },
  //       {
  //         text: "Hikmah Academy of California",
  //         iconStart: <DomainRoundedIcon />,
  //         IconStart: true,
  //       },
  //       {
  //         linkText: "realomersuleiman@gmail.com",
  //         linkTextStatus: true,
  //         link: "#",
  //       },
  //       { text: "Manual" },
  //       { text: "21-Jan-2024" },
  //       {
  //         Status: true,
  //         background: "#E8EAED",
  //         color: "#5C677D",
  //         linebackground: "#5C677D ",
  //         statusLine: true,
  //         label: "Invitation Pending",
  //       },
  //       {
  //         ActionButtonDotIcon: true,
  //         ActionButtonLabel: "Action",
  //         TextAlign: "right",
  //       },
  //     ],

  //     //======= Action Date Start===== //
  //     action: [
  //       { label: "View", onClick: handleDrawerOpenDetails },
  //       { label: "Edit", onClick: onClick },
  //       { label: "Delete", onClick: onClick },
  //     ],
  //     //======= Action Date End===== //
  //   },
  // ];

  //===== Table Data End === //

  return (
    <div>
      <Table
        headCells={headCells}
        rows={rows}
        footer={true}
        tableHeader={true}
      />

      <SideDrawer
        title="Prospect Details"
        ButtonLabel="Invite"
        cancelButtonLabel="Cancel"
        DeleteButton={true}
        sideOpen="right"
        open={openDetails}
        handleDrawerClose={handleDrawerCloseDetails}
        FooterWithoutTab={false}
        body={
          <p>
            <ProspectsDetails
              handleResendInvitation={handleResendInvitation}
              organizationInvitationDetails={organizationInvitationDetails}
              handleDeleteInvitation={handleDeleteInvitation}
            />
          </p>
        }
      />
    </div>
  );
};

export default Prospects;
