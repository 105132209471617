import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import TableHeader from "../../components/Table/TableHeader";
import "../../assets/css/ProspectDetails.css";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Buttons from "../../components/Form/Button/Button";
import ChipStatus from "../../components/Status/ChipStatus";
import DraggableDialog from "../../components/Dialog/Dialog";
import moment from "moment-timezone";

const ProspectsDetails = ({
  handleResendInvitation,
  organizationInvitationDetails,
  handleDeleteInvitation,
}) => {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
  }, []);
  //======= Approve Dialog ===== //
  const [openDeleteinvitationDialog, setOpenDeleteinvitationDialog] =
    useState(false);
  const handleOpenDeleteinvitationDialog = () => {
    setOpenDeleteinvitationDialog(true);
  };
  const handleCloseDeleteinvitationDialog = () => {
    setOpenDeleteinvitationDialog(false);
  };
  //======= Approve Dialog ===== //

  //============================== listing data===========================================//
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (organizationInvitationDetails?.activities?.length > 0) {
      setRows(convertCellsToTheRow(organizationInvitationDetails?.activities));
    }
  }, [organizationInvitationDetails]);
  //============================== listing data===========================================//
  //===== Table Data Started === //
  const convertCellsToTheRow = (array) => {
    if (array?.length > 0) {
      return array?.map((item) => {
        return {
          cell: [
            {
              date:
                item?.activity_name === "resend_invite" ||
                item?.activity_name === "send_invite"
                  ? `Invite sent to ${organizationInvitationDetails?.indEmailAddress} by ${item?.activityBy?.first_name} ${item?.activityBy?.last_name}`
                  : "Invite accepted",
            },
            {
              date: moment(item?.created_at)?.format("DD-MMM-YYYY h:mm A"),
              TextAlign: "right",
            },
          ],
        };
      });
    } else {
      return [];
    }
  };
  // const rows = [
  //   {
  //     cell: [
  //       { date: "Invite sent to qadhia2002@gmail.com by [Admin FullName]" },
  //       { date: "31-Dec-2023, 12:00 AM", TextAlign: "right" },
  //     ],
  //   },

  //   {
  //     cell: [
  //       { date: "Submitted become a teacher form" },
  //       { date: "31-Dec-2023, 12:00 AM", TextAlign: "right" },
  //     ],
  //   },
  // ];

  //===== Table Data End === //

  return (
    <div>
      <div className="ProspectDetails">
        <div className="ProspectDetailsLeft">
          <Avatar
            alt={organizationInvitationDetails?.indFirstName}
            src="/static/images/avatar/1.jpg"
          />
          <div className="ProspectDetailsText">
            <h1>
              {organizationInvitationDetails?.indFirstName}{" "}
              {organizationInvitationDetails?.indLastName}
            </h1>
            <p>
              Initially invited by {userData?.firstName} {userData?.lastName}
              on{" "}
              {moment(organizationInvitationDetails?.created_at)?.format(
                "DD-MMM-YYYY"
              )}
            </p>
          </div>
        </div>
        <div className="ProspectDetailsRight">
          <ChipStatus
            StatusLabel="Invitation Pending"
            StatusColor="#7D8597"
            StatusLine={true}
          />
        </div>
      </div>

      <div className="ProspectPersonainfo">
        <li>
          <p>Account type</p>
          <h2>
            {organizationInvitationDetails?.accountType === "org"
              ? "Organization"
              : "Individual" || "No data available"}
          </h2>
        </li>
        <li>
          <p>Email</p>
          <h2>
            {organizationInvitationDetails?.indEmailAddress ||
              "No data available"}
          </h2>
        </li>
        <li>
          <p>Phone</p>
          <h2>
            {organizationInvitationDetails?.indPhoneNumber ||
              "No data available"}
          </h2>
        </li>
        <li>
          <p>Stream</p>
          <h2>
            {organizationInvitationDetails?.stream || "No data available"}
          </h2>
        </li>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Buttons
              label="Resend invitation"
              buttonColor="#0450E1"
              border="#0450E1 1px solid"
              color="#fff"
              width="100%"
              height="40px"
              fontSize="14px"
              onClick={(e) =>
                handleResendInvitation(organizationInvitationDetails?.id)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Buttons
              label="Delete Invitation"
              buttonColor="#fff"
              border="#E8EAED 1px solid"
              color="#001233"
              width="100%"
              height="40px"
              fontSize="14px"
              onClick={handleOpenDeleteinvitationDialog}
            />
          </Grid>
        </Grid>
      </div>

      <div className="ActivityTaleContainer">
        <TableHeader TableTitle="Activity" />
        <Table rows={rows} footer={true} />
      </div>

      <DraggableDialog
        openDialog={openDeleteinvitationDialog}
        handleCloseDialog={handleCloseDeleteinvitationDialog}
        handleConfirmCloseDialog={(e) =>
          handleDeleteInvitation(organizationInvitationDetails?.id)
        }
        title="Delete invitation"
        body={
          <div>
            Are you sure you want to delete this invitation?
            <br /> Invitee will still be able to accept the invite using
            previous link.
          </div>
        }
        ModalFooter={true}
        actionButton="Delete"
      />
    </div>
  );
};

export default ProspectsDetails;
