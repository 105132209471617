import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SelectField from "../../components/Form/SelectField/SelectField";
import TextField from "../../components/Form/TextField/TextField";
import PhoneInputField from "../../components/Form/PhoneInputField/PhoneInputField";

const NewAccount = ({ setNewTeachingAccount, newTeachingAccount, errors }) => {
  //=== Select Field ==//
  const [accountType, setAccountType] = React.useState("Organization");
  const handleChanges = (event) => {
    setAccountType(event.target.value);
    setNewTeachingAccount({
      ...newTeachingAccount,
      accountType: event.target.value,
    });
  };
  const handleNewAccount = (val, fieldValue, fieldName) => {
    setNewTeachingAccount({
      ...newTeachingAccount,
      [fieldName]: fieldValue,
    });
  };

  const options = [
    {
      label: "Organization",
      value: "org",
    },

    {
      label: "Individual",
      value: "ind",
    },
  ];

  //=== Phone Field ==//
  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });
  const [phoneNumberFormated, setPhoneNumberFormated] = useState("");
  const handleChangesOfFields = (fieldName, phoneNumber, country, e) => {
    setNewTeachingAccount({
      ...newTeachingAccount,
      [fieldName]: e.target.value,
    });
    // console.log(fieldName, 345, phoneNumber, country, e);
  };

  // useEffect(() => {
  //   console.log(newTeachingAccount, 345);
  // }, [newTeachingAccount]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <SelectField
            label="Account type"
            FieldLabel={true}
            options={options}
            handleChange={handleChanges}
            value={accountType}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="First name"
            value={newTeachingAccount?.indFirstName}
            handleChange={(val, e) =>
              handleNewAccount(val, e.target.value, "indFirstName")
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Last name"
            value={newTeachingAccount?.indLastName}
            handleChange={(val, e) =>
              handleNewAccount(val, e.target.value, "indLastName")
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <div className="emailAddressInputContainer">
            <TextField
              label="Email address"
              email={true}
              required
              value={newTeachingAccount?.indEmailAddress}
              handleChange={(val, e) =>
                handleNewAccount(val, e.target.value, "indEmailAddress")
              }
              error={errors?.indEmailAddress}
              helperText={
                errors?.indEmailAddress
                  ? errors?.indEmailAddress
                  : "An invite will be sent to this email and the owner will use this email to login."
              }
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <PhoneInputField
            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
            country={"us"}
            // value={newTeachingAccount?.indPhoneNumber}
            countryCodeEditable={false}
            onChange={(phone, country, e) =>
              handleChangesOfFields("indPhoneNumber", `${phone}`, country, e)
            }
            // onBlur={() => console.log("onBlur")}
            // onKeyDown={(e) => console.log("onKeyDown", e.target.value, 345)}
            variant="outlined"
            // onlyCountries={["us", "ca", "gb", "my"]}
            containerStyle={{}}
            buttonStyle={{}}
            inputStyle={{
              marginLeft: "0px",
              height: "40px",
              width: "100%",
              fontFamily: "Roboto-Regular",
              fontSize: "16px",
            }}
            containerClass={{}}
            errorStyles={{ marginBottom: "5px" }}
            autoFormat={true}
            disableDropdown={false}
            disabled={false}
            disableCountryCode={false}
            enableSearch={false}
            disableSearchIcon={false}
            placeholder={""}
            inputProps={{}}
            specialLabel={"Primary Phone"}
            // preferredCountries={[]}
            // excludeCountries={[]}
            labelFixed="Phone number"
            LabelStatus={true}
            //error="error"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NewAccount;
