import React, { useEffect, useState } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Status from "../../components/Status/Status";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import DraggableDialog from "../../components/Dialog/Dialog";
import moment from "moment-timezone";
import { capitalizeFirstLetter } from "../../hooks/HelperFunctions";
import axios from "axios";
import { toast } from "react-toastify";

const TeachingProfile = ({ organizationDetails, getOrganizationDetails }) => {
  const baseUrl =
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;
  //======= Approve Dialog ===== //
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const handleOpenApproveDialog = () => {
    setOpenApproveDialog(true);
  };
  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(false);
  };
  //======= Approve Dialog ===== //

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const [statusData, setStatusData] = useState("");

  useEffect(() => {
    if (organizationDetails?.accountStatus) {
      setStatusData(capitalizeFirstLetter(organizationDetails?.accountStatus));
    }
  }, [organizationDetails]);

  const statusDropdownData = [
    { value: "Active", statuscolor: "#3CC480", select: "Active" },
    { value: "Blocked", statuscolor: "#C44058", select: "Blocked" },
    { value: "Pending", statuscolor: "#FF9800", select: "Pending" },
  ];

  const statustData = [
    {
      Label: "Stream",
      SubLabel: "Manual",
      Color: "#7D8597",
      line: false,
    },

    {
      Label: "Created",
      SubLabel: moment(organizationDetails?.created_at)?.format("DD-MMM-YYYY"),
      Color: "#7D8597",
      line: false,
    },
  ];

  const handleUpdateOrganizationStatus = (id, status) => {
    let data = {
      orgId: id,
      status: status, //pending, active, blocked
    };
    axios
      .post(`${baseUrl}/ownerPortal/updateOrganizationStatus`, data)
      .then((response) => {
        if (!response.data.error) {
          getOrganizationDetails();
          handleCloseApproveDialog();
          toast.success("TA status has been changed");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //============= copy part start ===================================//
  const handleCopyCode = (data) => {
    if (data === undefined) {
      navigator.clipboard.writeText("No data");
    } else {
      navigator.clipboard.writeText(data);
    }
  };
  //============= copy part start ===================================//

  const changeStaus = (e) => {
    console.log(e.target.value, 345);
    setStatusData(e.target.value);
    handleUpdateOrganizationStatus(organizationDetails?.orgId, e.target.value);
  };
  return (
    <div>
      {organizationDetails?.accountStatus &&
        capitalizeFirstLetter(organizationDetails?.accountStatus) !==
          "Active" && (
          <div className="ApproveAlertModal">
            <div className="ApproveAlertModalLeft">
              <WarningRoundedIcon /> Teaching account access disabled until
              approved.
            </div>
            <div className="ApproveButton">
              <Button variant="text" onClick={handleOpenApproveDialog}>
                Approve Now
              </Button>
            </div>
          </div>
        )}

      <Status
        MainTitle={true}
        Title={organizationDetails?.teachingAccName}
        BackLinkLabel="Back"
        Backlink="/accounts"
        StatusDropdownStatus={true}
        statusDropdownData={statusDropdownData}
        StaticStatus={true}
        onChange={(e) => changeStaus(e)}
        staticStatustData={statustData}
        Value={statusData}
        defaultValue={statusData}
      />

      <div className="AccountDetailTable">
        <div className="OddTableContainer">
          <div className="AccountDetailTableTitle">Account details</div>
          <TableContainer>
            <Table>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    align="left"
                    className="TableLabel"
                    width="30%"
                  >
                    Account ID
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {organizationDetails?.teachingAccRefId ||
                      "No data available "}
                  </StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Account Type
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {organizationDetails?.accountType === "org"
                      ? "Organization"
                      : "Individual"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    className="AccountCopyButton alignRight"
                  >
                    <Button
                      variant="text"
                      onClick={(e) =>
                        handleCopyCode(
                          organizationDetails?.accountType === "org"
                            ? "Organization"
                            : "Individual"
                        )
                      }
                    >
                      <ContentCopyIcon /> Copy
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Owner
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <p>
                      <a href="#">
                        {`${organizationDetails?.owner?.user?.first_name} ${organizationDetails?.owner?.user?.last_name}` ||
                          "No data available "}
                      </a>{" "}
                      {organizationDetails?.owner?.user?.email_verified_at && (
                        <span className="VerifyCheckIcon">
                          <CheckCircleRoundedIcon />
                        </span>
                      )}
                    </p>
                    <p>
                      {" "}
                      {organizationDetails?.owner?.user?.email ||
                        "No data available "}
                    </p>
                    <p>
                      {organizationDetails?.owner?.user
                        ?.phone_number_formatted || "No data available "}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    TA Page URL
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <a
                      href={`https://${organizationDetails?.subDomain}.mytakbir.com`}
                    >
                      https://{organizationDetails?.subDomain}.mytakbir.com
                    </a>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    className="AccountCopyButton alignRight"
                  >
                    <Button
                      variant="text"
                      onClick={(e) =>
                        handleCopyCode(
                          `https://${organizationDetails?.subDomain}.mytakbir.com`
                        )
                      }
                    >
                      <ContentCopyIcon /> Copy
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="AccountDetailTable">
        <div className="OddTableContainer">
          <div className="AccountDetailTableTitle">Organization details</div>
          <TableContainer>
            <Table>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell
                    align="left"
                    className="TableLabel"
                    width="30%"
                  >
                    Organization Name
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {organizationDetails?.orgFullName || "No data available "}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Category
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {organizationDetails?.orgType
                      ? capitalizeFirstLetter(organizationDetails?.orgType)
                      : "No data available "}
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Address
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    {organizationDetails?.orgStreetAddress ||
                      "No data available "}
                    <br />
                    {organizationDetails?.orgCity || "No data available "},{" "}
                    {organizationDetails?.orgState || "No data available "}
                    {organizationDetails?.orgZipCode || "No data available "}
                    <br />
                    {organizationDetails?.orgCountry || "No data available "}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <DraggableDialog
        openDialog={openApproveDialog}
        handleCloseDialog={handleCloseApproveDialog}
        title="Approve teaching account"
        handleConfirmCloseDialog={(e) =>
          handleUpdateOrganizationStatus(organizationDetails?.orgId, "active")
        }
        body={
          <div>
            Are you sure you want to approve{" "}
            <strong>{organizationDetails?.teachingAccName}</strong>
          </div>
        }
        ModalFooter={true}
        actionButton="Approve"
      />
    </div>
  );
};

export default TeachingProfile;
