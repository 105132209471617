import React, { useEffect, useState } from "react";

import TableHeader from "../../components/Table/TableHeader";
import TableSubHeader from "../../components/Table/TableSubHeader";
import TeachingAccounts from "./TeachingAccounts";
import SideDrawer from "../../components/SideDrawer/SideDrawer";
import NewAccount from "./NewAccount";
import Prospects from "./Prospects";
import axios from "axios";
import { isValidEmail } from "../../hooks/HelperFunctions";
import { toast } from "react-toastify";

const Accounts = () => {
  const baseUrl =
    process.env.REACT_APP_ENV === "prod"
      ? process.env.REACT_APP_API_URL_PROD
      : process.env.REACT_APP_API_URL_DEV;

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [teachingAccountsList, setTeachingAccountsList] = useState([]);
  const [
    getAllTheOrganizationInvitationList,
    setGetAllTheOrganizationInvitationList,
  ] = useState([]);
  const getTeachingAccounts = () => {
    axios
      .get(`${baseUrl}/ownerPortal/getCustomerListJson`)
      .then((response) => {
        if (!response.data.error) {
          setTeachingAccountsList(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProspects = () => {
    axios
      .post(`${baseUrl}/ownerPortal/getAllTheOrganizationInvitationList`)
      .then((response) => {
        if (!response.data.error) {
          setGetAllTheOrganizationInvitationList(response.data?.body);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTeachingAccounts();
    getProspects();
  }, []);

  const tabLabel = [
    {
      label: "Teaching Accounts",
    },
    {
      label: "Prospects",
    },
  ];

  const tabPanel = [
    {
      id: 0,
      panel: (
        <TeachingAccounts
          teachingAccountsList={teachingAccountsList}
          getTeachingAccounts={getTeachingAccounts}
        />
      ),
    },
    {
      id: 1,
      panel: (
        <Prospects
          getAllTheOrganizationInvitationList={
            getAllTheOrganizationInvitationList
          }
          getProspects={getProspects}
        />
      ),
    },
  ];

  //================================== New Teaching Account ==========================//
  const initialObject = {
    accountType: "",
    indFirstName: "",
    indLastName: "",
    indEmailAddress: "",
    indPhoneNumber: "",
    indPhoneNumberFormatted: "",
  };

  const [newTeachingAccount, setNewTeachingAccount] = useState(initialObject);

  const initialStateError = {
    indEmailAddress: "",
  };
  const [errors, setErrors] = useState(initialStateError);

  //==== New Teaching Account Drawer Start ===//
  const [openNew, setOpenNew] = useState(false);
  const handleDrawerOpenNew = () => {
    setOpenNew(true);
  };
  const handleDrawerCloseNew = () => {
    setOpenNew(false);
    setErrors(initialStateError);
    setNewTeachingAccount(initialObject);
  };
  //==== New Teaching Account Drawer End ===//

  //======================================= checking errors =============================//
  const checkingValidaty = () => {
    let errorObject = { ...errors };
    let error = 0;

    if (
      newTeachingAccount.indEmailAddress == "" ||
      !isValidEmail(newTeachingAccount.indEmailAddress)
    ) {
      errorObject = {
        ...errorObject,
        indEmailAddress: "Enter a valid email address",
      };
      error++;
    } else {
      errorObject = { ...errorObject, indEmailAddress: "" };
    }

    setErrors({ ...errors, ...errorObject });

    return error > 0 ? false : true;
  };
  //======================================= checking errors =============================//

  const handleInvitingNewTeachingAccount = () => {
    const isValid = checkingValidaty();
    if (!isValid) {
      return false;
    }

    let data = { ...newTeachingAccount };
    axios
      .post(`${baseUrl}/ownerPortal/inviteOrganization`, data)
      .then((response) => {
        if (!response?.data?.error) {
          toast.success("Invite sent");
          setErrors(initialStateError);
          setNewTeachingAccount(initialObject);
          handleDrawerCloseNew();
          getProspects();
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  //================================== New Teaching Account ==========================//

  return (
    <div>
      <TableHeader
        TableTitle="Accounts"
        AddButton={true}
        AddButtonLabel="Invite new"
        AddButtonOnClick={handleDrawerOpenNew}
      />

      <TableSubHeader
        Tabs={true}
        tabLabel={tabLabel}
        tabPanel={tabPanel}
        TabValue={value}
        TabHandleChange={handleChange}
        Filter={false}
        //FilterOnClick={FilterOnClick}

        Export={false}
        //ExportOnClick={ExportOnClick}

        //== Search Code == //
        Search={false}
        SearchPlaceholder="Search..."
        // SearchValue={searchTerm}
        //SearchOnChange={handleSearchText}
        //SearchOnclick={SearchOnclick}
        //SearchOnMouseDown={SearchOnMouseDown}
        //handleSearch={handleSearch}
        Border={true}
      />

      <SideDrawer
        title="New Teaching Account"
        ButtonLabel="Invite"
        cancelButtonLabel="Cancel"
        DeleteButton={true}
        sideOpen="right"
        open={openNew}
        handleDrawerClose={handleDrawerCloseNew}
        FooterWithoutTab={true}
        clickSaveButton={handleInvitingNewTeachingAccount}
        body={
          <p>
            <NewAccount
              setNewTeachingAccount={setNewTeachingAccount}
              newTeachingAccount={newTeachingAccount}
              errors={errors}
            />
          </p>
        }
      />
    </div>
  );
};

export default Accounts;
