import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

// * === Public routes start === * //
import MainLayout from "./routes/MainLayout";
import SignIn from "./pages/Authentication/SignIn";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ResetPassword from "./pages/Authentication/ResetPassword";
import Otp from "./pages/Authentication/Otp";
// * ====  Public routes end  ==== * //

// * ======= HOME =======* //
import Home from "./pages/Home/Home";
// * ======= HOME =======* //

// * ======= INBOX =======* //
import Inbox from "./pages/Inbox/Inbox";
// * ======= INBOX =======* //

// * ======= ACCOUNTS =======* //
import Accounts from "./pages/Accounts/Accounts";
import AccountsDetails from "./pages/Accounts/AccountsDetails";
// * ======= ACCOUNTS =======* //

// * ======= USERS =======* //
import Users from "./pages/Users/Users";
// * ======= USERS =======* //

// * ======= TEAM =======* //
import Team from "./pages/Team/Team";
// * ======= TEAM =======* //

// * ======= SETTINGS =======* //
import Settings from "./pages/Settings/Settings";
import axios from "axios";
// * ======= SETTINGS =======* //

function App() {
  const userToken = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="/inbox"
          element={
            <MainLayout>
              <Inbox />
            </MainLayout>
          }
        />
        <Route
          path="/accounts"
          element={
            <MainLayout>
              {" "}
              <Accounts />{" "}
            </MainLayout>
          }
        />
        <Route path="/accounts-details/:id" element={<AccountsDetails />} />
        <Route
          path="/users"
          element={
            <MainLayout>
              <Users />
            </MainLayout>
          }
        />
        <Route
          path="/team"
          element={
            <MainLayout>
              <Team />
            </MainLayout>
          }
        />
        <Route
          path="/settings"
          element={
            <MainLayout>
              <Settings />
            </MainLayout>
          }
        />

        <Route path="/signin" element={<SignIn />} />
      </Routes>
    </>
  );
}

export default App;
