import React, { useState } from "react";
// import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Drawer from '@material-ui/core/Drawer';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import IconButton from '@material-ui/core/IconButton';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import "./SideBarDrawer.css";
import { CssBaseline, Drawer, IconButton, useTheme } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

function SideBarDrawer(props) {
  const { open, handleDrawerClose } = props;
  const theme = useTheme();

  return (
    <div className="SideBarDrawer" style={{ paddingTop: "0px" }}>
      <div>
        <CssBaseline />

        <Drawer variant="persistent" anchor="left" open={open}>
          <div className="MenuIconClose">
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ArrowBackIosNewRoundedIcon />
              ) : (
                <ArrowForwardIosRoundedIcon />
              )}
            </IconButton>
          </div>

          {props.DrawerBody}
        </Drawer>
      </div>
    </div>
  );
}

export default SideBarDrawer;
